















































import { Component, Vue } from "vue-property-decorator";
import api from "@/api";
import store from "@/store";
import { getLastDay } from "@/utils/common";

@Component({})
export default class accountDetail extends Vue {
  // 字典
  config = store.state.system.config;
  // 表格数据
  tableData = [];
  // 加载状态
  loading = false;
  // 总条数
  total = 0;
  // 搜索条件
  search: any = {
    keyword: '',
    channelId: '',
    sendingOrganizationCode: '',
    pagination: {
      page: 0, //页码，从0开始
      pageSize: 20, //每页数量
    },
    bidMonth: "",
    tradingCenterCode: ""
  };


  pickerOptions = {
    disabledDate: (time: Date) => {
      let min_tiem =
        new Date(this.search.bidMonth + "-01").getTime() - 86400000;
      let ym = this.search.bidMonth.slice("-");
      let max_tiem = new Date(
        this.search.bidMonth +
          "-" +
          getLastDay(parseInt(ym[0]), parseInt(ym[1]))
      ).getTime();
      return time.getTime() < min_tiem || time.getTime() >= max_tiem;
    },
  };

  // 搜索 search:true 重置翻页
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
      .post("J30106", this.search)
      .then((data) => {
        this.total = data.total;
        this.tableData = data.list;
      })
      .finally(() => {
        this.loading = false;
      });
  }
  //获取交易中心
  channelList: any = {}
  channelDict() {
    api
      .post('A13005').then(res => {
        this.channelList = res
      })
  }

  activated() {
    let { channelId, bidMonth, tradingCenterCode, sendingOrganizationCode } = this.$route.query
    this.search.channelId = channelId
    this.search.bidMonth = bidMonth
    this.search.sendingOrganizationCode = sendingOrganizationCode
    this.search.tradingCenterCode = tradingCenterCode
    this.searchTable();
    this.channelDict()
  }

}
